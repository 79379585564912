<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Layouts </v-card-title>
      <CButton
        color="primary"
        class="px-4"
        style="float: right"
        @click="crear()"
        ><CIcon name="cil-user" /> Nuevo</CButton
      >

      <v-card-text>
        <filters
          :campos="filterFields"
          :itemsKeys="{
            text: 'name',
            value: 'id',
          }"
          :eventName="'fetchItemsEvent'"
        ></filters>
        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        <CDataTable
          v-if="!loading"
          hover
          striped
          :items="items"
          :fields="fields"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
          <template #activo="data">
            <td>
              <CBadge :color="data.item.activo?'success':'secondary'">
                {{ data.item.activo==1?'Habilitado':'Inhabilitado' }}
              </CBadge>
            </td>
          </template>
          <template #acciones="data">
            <td>
              <CLink
                href="#"
                @click="campos(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Campos'"
              >
                <CIcon name="cil-grid" />
              </CLink>
              <CLink
                v-if="data.item.nombre != 'REPOSITORIO FOTOGRAFICO'"
                href="#"
                @click="editar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Editar'"
              >
                <CIcon name="cil-pencil" />
              </CLink>
              <CLink
                href="#"
                @click="detalles(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Detalles'"
              >
                <CIcon name="cil-list" />
              </CLink>
              <CLink
                href="#"
                @click="archivos(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Cargar Archivos'"
              >
                <CIcon name="cil-file" />
              </CLink>
            </td>
          </template>
        </CDataTable>
      </v-card-text>
    </v-card>
    <br><br>
  </div>
</template>

<script>
import Filters from "@/components/filters";
import LayoutService from "@/services/layout.service"
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Layout",
  data() {
    return {
      items: [],
      fields: [
        { key: "id", label: "Código" },
        { key: "nombre", label: "Nombre", _classes: "font-weight-bold" },
        { key: "tema_nombre", label: "Grupo tema" },
        { key: "activo", label: "Estado" },
        { key: "acciones", label: "Acciones" },
      ],
      filterFields: [
        { value: "nombre", label: "Nombre" },
        {
          value: "activo",
          label: "Estado",
          options: [
            { id: 1, name: "Habilitado" },
            { id: 0, name: "Inhabilitado" },
          ],
        },
      ],
      activePage: 1,
      loading: false,
      message: "",
    };
  },
  components: {
    Filters,
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Definición de layouts"))
      this.$router.push("/dashboard");
    this.fetchItems("");
    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      this.fetchItems(cadenaBusqueda);
    });
  },
  methods: {
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      LayoutService.getItems(cadenaBusqueda).then(
        (response) => {
          this.items = response.data;
          console.log(response.data);
          this.loading = false;
          this.items = this.items.map(function f(x) {
            return {...x,
             'tema_nombre': x.tema.valor_texto};
          });
          console.log(this.items)
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    rowClicked(item) {
      this.$router.push({ path: `layouts/${index + 1}` });
    },
    detalles(item) {
      localStorage.setItem("datos_layout", JSON.stringify(item));
      this.$router.push({ path: `layouts/layout` });
    },
    crear() {
      localStorage.removeItem("datos_layout");
      this.$router.push({ path: `layouts/editar` });
    },
    editar(item) {
      localStorage.setItem("datos_layout", JSON.stringify(item));
      this.$router.push({ path: `layouts/editar` });
    },
    campos(item) {
      localStorage.setItem("datos_layout", JSON.stringify(item));
      this.$router.push({ path: `layouts/campos` });
    },
    archivos(item) {
      localStorage.setItem("datos_layout", JSON.stringify(item));
      this.$router.push({ path: `layouts/archivos` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>
